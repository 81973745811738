
import {throwError as observableThrowError,  Observable ,  Subject, BehaviorSubject } from 'rxjs';

import {share, mergeMap, zip, retryWhen, catchError,  map } from 'rxjs/operators';
// file: server-socket.service.ts
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders} from '@angular/common/http';
// import { QueueingSubject } from 'queueing-subject'
// import websocketConnect from 'rxjs-websockets'
import { openDB } from 'idb';

@Injectable()
export class DBGlobal {
  // private inputStream: QueueingSubject<string>
  private _dbPromise;
  private currentLocation = {};
  private _dataChange: Subject<[{}]> = new Subject<[{}]>();
  public coreExtraIcon = '';
  public corePropertyFilters = {};
  public messages: Observable<string>
  public user = new BehaviorSubject<any>({});
  private BASE_URL = '/api/';
  private headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
  public firmwareList = {};
  public firmwareListKeys = [];
  public firmwareDefault = [];
  public chlorineDispensing = 1;
  public week = {};
  public routes = {};
  public chemicalOnlyStops = {};
  public fullServiceStops = {};
  public sosStops = {};
  public smartStops = {};
  public avgWaterTemp = 77;
  public showSpanish = Boolean(localStorage.getItem('showSpanish') || 'false');
  public chemicalOnlyData = {};
  public fullServiceData  = {};
  public sosData = {};
  public smartData  = {};
  public app = '';
  public chemicalOnlyGroups = {};
  public fullServiceGroups = {};
  public sosGroups = {};
  public smartGroups = {};
  public cleaningRoutes = {};
  public customerZones = {};
  public cleaningWeek = '';
  public billingPlans = {};
  public billingCoupons = {};
  public global: any = {}
  public selectedDevice = '';
  public selectedStop = '';
  public operators = [];
  public agentUsers = [];
  public routeUsers = [];
  public supervisorUsers = [];
  public propertyManagementUsers = [];
  public operatorUsers = [];
  public device = new BehaviorSubject<any>({});
  public deviceCTR = new BehaviorSubject<any>({});
  public archives = new BehaviorSubject<any>([]);
  public tickets = new BehaviorSubject<any>([]);
  public switchWaiting = new BehaviorSubject<any>(false);
  // public archive = new BehaviorSubject<any>({});
  public hasLocalDB = false;
  public oprUser = new BehaviorSubject<any>({});
  public oprDevice = new BehaviorSubject<any>({});
  public oprDevices = new BehaviorSubject<any>({});
  public oprFilters = new BehaviorSubject<any>({});
  public oprDevicesRaw = new BehaviorSubject<any>({});
  public oprDeviceUpdate = new BehaviorSubject<any>({});
  public oprHistory = new BehaviorSubject<any>({});
  public activeConversation = new BehaviorSubject<any>({});
  public activeConversationDevice = new BehaviorSubject<any>({});
  public refreshFilters = false;
  public reloadSkip = 0;
  public activeDeviceIdx = 0;
  public activeScheduleIdx = 0;
  public activeCard = new BehaviorSubject<number>(1);
  public dashLoading = false;
  public devicesFilter = 'active';
  public devicesFilterExtra = '';
  public devicesFilterText = '';
  public remoteChooseColor = new BehaviorSubject<any>(''); // '';
  public zone = Number(sessionStorage.getItem('devicesZone')) || 0; // 0;
  public page =  Number(sessionStorage.getItem('devicesPage')) || 1;  // 1;
  public environmentLoaded = false;
  public voiceQueue = {
    averageWaitTime: 0,
    currentSize: 0,
  }
  public route = [];
  public validAreaCode = [205, 251, 256, 334, 659, 938, 907, 250, 480, 520, 602, 623, 928, 327, 479, 501, 870, 209, 213, 310,
                          369, 408, 415, 424, 442, 510, 530, 559, 562, 619, 626, 627, 628, 650, 657, 661, 669, 707, 714, 747, 760,
                          764, 805, 818, 831, 858, 909, 916, 925, 935, 949, 951 , 303, 719, 720, 970 , 203, 475, 860, 959, 302, 202,
                          239, 305, 321, 352, 386, 407, 561, 689, 727, 754, 772, 786, 813, 850, 863, 904, 941, 954 , 229, 404, 470,
                          478, 678, 706, 762, 770, 912 , 808, 208, 217, 224, 309, 312, 331, 447, 464, 618, 630, 708, 730, 773, 779,
                          815, 847, 872, 219, 260, 317, 574, 765, 812, 319, 515, 563, 641, 712 , 316, 620, 785, 913, 270, 364, 502,
                          606, 859, 225, 318, 337, 504, 985, 207, 227, 240, 301, 410, 443, 667, 339, 351, 413, 508, 617, 774, 781,
                          857, 978, 231, 248, 269, 313, 517, 586, 616, 679, 734, 810, 906, 947, 989 , 218, 320, 507, 612, 651, 201,
                          763, 952, 228, 601, 662, 769, 314, 417, 557, 573, 636, 660, 816, 975, 406, 308, 402, 531, 702, 775, 603,
                          551, 609, 732, 848, 856, 862, 908, 973 , 505, 575, 212, 315, 347, 516, 518, 585, 607, 631, 646, 716, 323,
                          718, 845, 914, 917, 929 , 252, 336, 704, 828, 910, 919, 980, 984 , 701, 216, 234, 283, 330, 380, 419,
                          440, 513, 567, 614, 740, 937, 405, 539, 580, 918, 458, 503, 541, 971, 215, 267, 272, 412, 445, 484, 570,
                          582, 610, 717, 724, 814, 835, 878 , 401, 803, 843, 864, 605, 423, 615, 731, 865, 901, 931, 210, 214,
                          254, 281, 325, 361, 409, 430, 432, 469, 512, 682, 713, 737, 806, 817, 830, 832, 903, 915, 936, 940, 341,
                          956, 972, 979 , 385, 435, 801, 802, 276, 434, 540, 571, 703, 757, 804 , 206, 253, 360, 425, 509, 564,
                          304, 681, 262, 274, 414, 534, 608, 715, 920, 307]
  // public oprBridge = new BehaviorSubject<String>('');

  public lightModels = {
    '-1': {
      label: 'White Generic'
    },
    '0': {
      label: 'Color Generic',
      colorsIdx: [1, 20]
    },
    '1': {
      label: 'Jandy Water Colors',
      colors: {
        // 0: { label: '' },
        1: { label: 'Alpine White', background: 'radial-gradient(circle, White, White)'},
        2: { label: 'Sky Blue', background: 'radial-gradient(circle, DeepSkyBlue, DeepSkyBlue)'},
        3: { label: 'Cobalt Blue', background: 'radial-gradient(circle, RoyalBlue, RoyalBlue)'},
        4: { label: 'Caribbean Blue', background: 'radial-gradient(circle, Turquoise, Turquoise)'},
        5: { label: 'Spring Green', background: 'radial-gradient(circle, LawnGreen, LawnGreen)'},
        6: { label: 'Emerald Green', background: 'radial-gradient(circle, PaleGreen, PaleGreen)'},
        7: { label: 'Emerald Rose', background: 'radial-gradient(circle, Orchid, Orchid)'},
        8: { label: 'Magenta', background: 'radial-gradient(circle, Magenta, Magenta)'},
        9: { label: 'Violet', background: 'radial-gradient(circle, Violet, Violet)'},
        10: { label: 'Slow Color Splash', background: 'radial-gradient(circle, White, Magenta, blue , green, red, White, Magenta, blue, green, red)'},
        11: { label: 'Fast Color Splash', background: 'radial-gradient(circle, White, Magenta, blue , green, red, White, Magenta, blue, green, red)'},
        12: { label: 'American the Beautiful', background: 'radial-gradient(circle, White, Magenta, blue , green, red, White, Magenta, blue, green, red)'},
        13: { label: 'Fat Tuesday', background: 'radial-gradient(circle, White, Magenta, blue , green, red, White, Magenta, blue, green, red)'},
        14: { label: 'Disco Tech', background: 'radial-gradient(circle, White, Magenta, blue , green, red, White, Magenta, blue, green, red)'}
      },
      colorsIdx: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
    },
    '2': {
      label: 'Pentair IntelliBrite',
      colors: {
        1: { label: 'SAm', background: 'radial-gradient(circle, White, Magenta, blue , green, red, White, Magenta, blue, green, red)'},
        2: { label: 'Party', background: 'radial-gradient(circle, White, Magenta, blue , green, red, White, Magenta, blue, green, red)'},
        3: { label: 'Romance', background: 'radial-gradient(circle, White, Magenta, blue , green, red, White, Magenta, blue, green, red)'},
        4: { label: 'Caribbean', background: 'radial-gradient(circle, White, Magenta, blue , green, red, White, Magenta, blue, green, red)'},
        5: { label: 'American', background: 'radial-gradient(circle, White, Magenta, blue , green, red, White, Magenta, blue, green, red)'},
        6: { label: 'California Sunset', background: 'radial-gradient(circle, White, Magenta, blue , green, red, White, Magenta, blue, green, red)'},
        7: { label: 'Royal', background: 'radial-gradient(circle, White, Magenta, blue , green, White, Magenta, blue, green)'},
        8: { label: 'Blue', background: 'radial-gradient(circle, Blue, Blue)'},
        9: { label: 'Green', background: 'radial-gradient(circle, Green, Green)'},
        10: { label: 'Red', background: 'radial-gradient(circle, Red, Red)'},
        11: { label: 'White', background: 'radial-gradient(circle, White, White)'},
        12: { label: 'Magenta', background: 'radial-gradient(circle, Magenta, Magenta)'},
        // 13: { label: 'Hold'},
        // 14: { label: 'Recall'}
      },
      colorsIdx: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    },
    '3': {
      label: 'Fusion Pool G4',
      colors: {
        // 0: { label: ''},
        1: {
          label: 'Fluid Color Transition',
          background: `radial-gradient(circle, rgba(244,244,244,1) 12%, rgba(0,14,228,1) 24%, rgba(189,0,228,1) 36%,
                       rgba(0,228,189,1) 48% , rgba(18,228,0,1) 60%, rgba(187,130,226,1) 72%, rgba(0,228,224,1) 84%,
                       rgba(0,228,224,1) 100%)`,
          color: 'grey'
        },
        // 2: { label: 'Last Color Saved'},
        3: {
          label: 'Crystal White',
          background: 'radial-gradient(circle, rgba(244,244,244,1) 42%, rgba(204,204,204,1) 85%, rgba(172,172,172,1) 100%)',
          color: 'grey'
        },
        4: {
          label: 'Ocean Blue',
          background: 'radial-gradient(circle, rgba(0,14,228,1) 42%, rgba(116,123,208,1) 85%, rgba(172,172,172,1) 100%)',
          color: 'grey'
        },
        5: {
          label: 'Magenta',
          background: 'radial-gradient(circle, rgba(189,0,228,1) 42%, rgba(208,116,205,1) 85%, rgba(172,172,172,1) 100%)',
          color: 'grey'
        },
        6: {
          label: 'Aqua Green',
          background: 'radial-gradient(circle, rgba(0,228,189,1) 42%, rgba(116,208,200,1) 85%, rgba(172,172,172,1) 100%)',
          color: 'grey'
        },
        7: {
          label: 'True Green',
          background: 'radial-gradient(circle, rgba(18,228,0,1) 42%, rgba(123,208,116,1) 85%, rgba(172,172,172,1) 100%)',
          color: 'grey'
        },
        8: {
          label: 'Purple Haze',
          background: 'radial-gradient(circle, rgba(187,130,226,1) 42%, rgba(190,184,208,1) 85%, rgba(172,172,172,1) 100%)',
          color: 'grey'
        },
        9: {
          label: 'Caribbean Blue',
          background: 'radial-gradient(circle, rgba(0,228,224,1) 42%, rgba(116,208,208,1) 85%, rgba(172,172,172,1) 100%)',
          color: 'grey'
        },
        10: {
          label: 'Synergy Mode',
          background: `radial-gradient(circle, rgba(244,244,244,1) 5%, rgba(0,14,228,1) 10%, rgba(189,0,228,1) 15%,
                       rgba(0,228,189,1) 20% , rgba(18,228,0,1) 25%, rgba(187,130,226,1) 30%, rgba(0,228,224,1) 35%,
                       rgba(244,244,244,1) 40%, rgba(0,14,228,1) 45%, rgba(189,0,228,1) 50%,
                       rgba(0,228,189,1) 55% , rgba(18,228,0,1) 60%, rgba(187,130,226,1) 65%, rgba(0,228,224,1) 70%,
                       rgba(244,244,244,1) 75%, rgba(0,14,228,1) 80%, rgba(189,0,228,1) 85%,
                       rgba(0,228,189,1) 90% , rgba(0,228,224,1) 100%)`,
          color: 'grey'
        },
        11: {
          label: 'Jungle Wash Mood',
          background: `radial-gradient(circle, rgba(244,244,244,1) 5%, rgba(0,14,228,1) 20%, rgba(189,0,228,1) 35%,
                       rgba(0,228,189,1) 50% , rgba(18,228,0,1) 65%, rgba(187,130,226,1) 80%, rgba(0,228,224,1) 95%,
                       rgba(0,228,224,1) 100%)`,
          color: 'grey'
        }
      },
      colorsIdx: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    }
  }

  public lightModelsIdx = ['-1', '0', '1', '2', '3'];

  public requestTypes = {
    poolCleaning: {
      label: 'Pool Cleaning',
      actions: ['inspection', 'skimmer', 'vacuum', 'brush', 'cleanPool', 'basket', 'backwash'],
      cost: 50,
      billTag: 'plan_poolCleaning'
    },
    filterCleaning: {
      label: 'Filter Deep Cleaning',
      actions: ['inspection', 'filter'],
      cost: 120,
      billTag: 'plan_filterCleaning'
    },
    saltCellCleaning: {
      label: 'Salt Cell Cleaning',
      actions: ['inspection', 'saltCell'],
      cost: 50,
      billTag: 'plan_saltCellCleaning'
    },
    waterBalancing: {
      label: 'Pool Water Analysis',
      actions: ['inspection', 'waterTest', 'refill', 'saltCell', 'calibration'],
      cost: 0
    },
    chemicalsRefill: {
      label: 'Chemicals Refill',
      actions: ['inspection', 'refill', 'calibration'],
      cost: 0
    },
    equipmentDiagnostic: {
      label: 'Equipment Diagnostic',
      actions: ['inspection'],
      cost: 0
    },
    quoteRequest: {
      label: 'Quote Request',
      actions: ['quoteSent'],
      cost: 0
    }
  }

  public alertInfo = {
    CT_AirDetect: {
        label: 'Air Detected',
        type: 'critical',
        message: `The controller has detected air on your filtration system. Please,
                    check the pool water level and/or inspect your pool equipment`,
    },
    CT_NoPressureDetect: {
        label: 'No Pressure',
        type: 'critical',
        message: `The controller has not detected no pressure while the pump is running.
                    Please, inspect your filter pump `,
    },
    CT_FreezeDetect: {
        label: 'Freeze Protect',
        type: 'information',
        message: `The controller has detected freezing temperatures. The controller will
                    run freeze protected peripherals until non-freezing temperatures`,
    },
    CT_ServiceMode: {
        label: 'Service Mode',
        type: 'information',
        message: `Service Mode has been activated. The controller will allow access
                    for one hour`,
    },
    AI_LowChlorine: {
        label: 'Low Chlorine',
        type: 'warning',
        message: `The controller has detected less than 2 gallons of chlorine. Please, refill chlorine as soon as possible.`
    },
    AI_LowAcid: {
        label: 'Low Muriatic',
        type: 'warning',
        message: `The controller has detected less than 2 gallons of muriatic acid. Please, refill muriatic acid as soon as possible.`
    },
    AI_LowChlorineUsage: {
        label: 'Low Chlorine Dispensing',
        type: 'warning',
        message: `The controller has detected unusually low chlorine consumption`
    },
    AI_HighChlorineUsage: {
        label: 'High Chlorine Dispensing',
        type: 'warning',
        message: `The controller has detected unusually high chlorine consumption`
    },
    AI_HighAcidUsage: {
        label: 'Low Muriatic',
        type: 'warning',
        message: `The controller has detected unusually high muriatic acid consumption`
    },
    AI_LowORP: {
        label: 'Low ORP',
        type: 'warning',
        message: `The controller has detected low ORP levels. Please, monitor your ORP levels`
    },
    AI_LowPH: {
        label: 'Low PH',
        type: 'warning',
        message: `The controller has detected low PH levels. Please, add soda ash`,
    },
    AI_HighPH: {
        label: 'High PH',
        type: 'warning',
        message: `The controller has detected high PH levels. Please, monitor your PH levels`,
    },
    AI_SkippedSchedule: {
        label: 'Missed Schedule',
        type: 'warning',
        message: `The controller has detected a discrepancy on schedule execution. Please,
                  check your WiFi connection and/or equipment's power source`,
    }
  }

  constructor(private http: HttpClient) {
    if (localStorage.getItem('activeDeviceIdx')) {
      this.activeDeviceIdx = Number(localStorage.getItem('activeDeviceIdx'));
      if (this.user.value.hasOwnProperty('_id') && this.user.value.devices[this.activeDeviceIdx] &&
          this.user.value.devices[this.activeDeviceIdx].device.hasOwnProperty('settings')) {
        this.device.next(this.user.value.devices[this.activeDeviceIdx]);
      }
    } else {
      this.activeDeviceIdx = 0;
      if (this.user.value.hasOwnProperty('_id') && this.user.value.devices[this.activeDeviceIdx] &&
          this.user.value.devices[this.activeDeviceIdx].device.hasOwnProperty('settings')) {
        this.device.next(this.user.value.devices[this.activeDeviceIdx]);
      }
    }
    this.onUpdateFirmware();
  }

  public updateGlobal(input): Promise<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));

    return this.http.post(this.BASE_URL + 'global/update', input, {headers: headers})
                    .toPromise()
                    .catch(this.handleError);
  }

  public getGlobal(input): Promise<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));

    return this.http.post(this.BASE_URL + 'global/get', input, {headers: headers})
                    .toPromise()
                    .catch(this.handleError);
  }

  public listDevices(data: any): Observable<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));

    return this.http.post(this.BASE_URL + 'device/list', data, {headers: headers})
                     .pipe(catchError(this.handleError));
  }

  public toggleSpanish() {
    this.showSpanish = !this.showSpanish;
    localStorage.setItem('showSpanish', String(this.showSpanish))
    console.log('Spanish', this.showSpanish)
  }



  onUpdateFirmware() {
    this.getGlobal({})
        .then((res) => {
          // console.log('zones-0')
          if (res.success) {
            this.firmwareList = res.data.firmwareList;
            if (res.data.firmwareList) {
              this.firmwareListKeys = Object.keys(res.data.firmwareList);
            }
            this.firmwareDefault = res.data.firmwareDefault;
            this.billingPlans = res.data.plans;
            this.billingCoupons = res.data.coupons;
            this.week = res.data.week;
            this.avgWaterTemp = res.data.avgWaterTemp
            this.app = res.data.app;
            this.agentUsers = res.data.agentUsers
            this.operatorUsers = res.data.operatorUsers
            this.routeUsers = res.data.routeUsers
            this.supervisorUsers = res.data.supervisorUsers
            this.propertyManagementUsers = res.data.propertyManagementUsers
            
            this.chlorineDispensing = Number(res.data.chlorineDispensing)
            this.voiceQueue = res.data.voiceQueue;

            this.environmentLoaded = true;
            // this.routes = res.data.routes;
            // this.cleaningRoutes = res.data.cleaningRoutes;
            // this.cleaningWeek = res.data.cleaningWeek;
            // this.customerZones = res.data.customerZones;
            // this.chemicalOnlyGroups = res.data.chemicalOnlyGroups;
            // this.chemicalOnlyStops = res.data.chemicalOnlyStops;
            // this.chemicalOnlyData = res.data.chemicalOnlyData;

            // this.fullServiceGroups = res.data.fullServiceGroups;
            // this.fullServiceStops = res.data.fullServiceStops;
            // this.fullServiceData = res.data.fullServiceData;


            // this.sosGroups = res.data.sosGroups;
            // this.sosStops = res.data.sosStops;
            // this.sosData = res.data.sosData;

            // this.smartGroups = res.data.smartGroups;
            // this.smartStops = res.data.smartStops;
            // this.smartData = res.data.smartData;
            // this.onRefreshFilter();
          } else {
            console.log('fail');
          }
        })
        .catch((err) => {
            console.log(err);
        });
  }

  // process devices 
  // get distance between two points



  private handleError (error) {
    // console.log(error);
    // window.location.reload();
    return observableThrowError(error);
  }
}
