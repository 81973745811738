
import {throwError as observableThrowError, timer as observableTimer, range as observableRange,  Observable } from 'rxjs';

import {mergeMap, zip,  map ,  retryWhen ,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { User } from '../models/user';

@Injectable()
export class UserService {
  private BASE_URL = '/api/';
  private headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
  constructor(private http: HttpClient) {}
  register(user: User): Promise<any> {
    return this.http.post(this.BASE_URL + 'user/signup', user, {headers: this.headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }

  updateProfile(profile: any): Promise<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'user/update', profile, {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }

  deleteProfile(profile: any): Promise<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'user/delete', profile, {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }

  getUser(data: any): Observable<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'user/get', data, {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    // .pipe(retryWhen(
                    //   attempts => attempts.pipe(
                    //     zip(observableRange(1, 120), (_, i) => i),
                    //     mergeMap(i => {
                    //       return observableTimer(i * 1000);
                    //     }),)
                    // ))
                    // .pipe(catchError(this.handleError));
  }

  getSelf(data: any): Observable<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'user/getSelf', data, {headers: headers})
  }

  listUsers(input): Promise<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'user/list', input, {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }

  linkDevice(device: any): Promise<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'user/link', device, {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }

  private handleError (error) {
    console.log(error);
    // window.location.reload();
    return observableThrowError(error);
  }
}
