
import {throwError as observableThrowError, timer as observableTimer, range as observableRange,  Observable } from 'rxjs';

import {mergeMap, zip, retryWhen, catchError,  map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class DeviceService {
  private BASE_URL = '/api/';
  private headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
  constructor(private http: HttpClient) {}

  newDevice(): Promise<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'device/new', {}, {headers: headers})
    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }

  updateDevice(data: any): Promise<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'device/update', data, {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }
  updateRoute(data: any): Promise<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'device/route', data, {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }
  deleteDevice(data: any): Promise<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'device/delete', data, {headers: headers})
                    .toPromise()
                    .catch(this.handleError);
  }
  awsUploadUrl(data: any): Promise<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'device/awsUploadUrl', data, {headers: headers})
                    .toPromise()
                    .catch(this.handleError);
  }
  awsUploadImg(url: any, data: any): Promise<any> {
    const headers = this.headers.set('Content-Type', 'image/jpeg');
    // headers = headers.set('x-amz-acl',  'public-read');
    // console.log(headers);
    return this.http.put(url, data, {headers: headers})
                    .toPromise()
                    .catch(this.handleError);
  }

  updateSubscription(data: any): Promise<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'device/updateSubscription', data, {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }

  updateDeviceControl(data: any): Observable<any> {
    let headers: HttpHeaders;
    if (localStorage.getItem('token') === null) {
      headers = this.headers.set('Authorization', '');
    } else {
      headers = this.headers.set('Authorization', localStorage.getItem('token'));
    }
    return this.http.post(this.BASE_URL + 'device/updateControl', data, {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    .pipe(
                    catchError(this.handleError));
  }

  bridge(data: any): Promise<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'device/bridge', data, {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }

  getDevice(data: any): Observable<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'device/get', data, {headers: headers})
                    .pipe(catchError(this.handleError));
  }

  alertDevice(data: any): Promise<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'device/alert', data, {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }

  resetDevice(data: any): Observable<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'device/reset', data, {headers: headers})
                    .pipe(catchError(this.handleError));
  }


  getDeviceService(data: any): Observable<any> {
    const headers = this.headers.set('Authorization', '');
    return this.http.post(this.BASE_URL + 'device/getService', data, {})
                    // .pipe(map((res: Response) => res.json()))
                    .pipe(catchError(this.handleError));
                    // .pipe(
                    // retryWhen(
                    //   attempts =>
                    //   attempts.pipe(
                    //     // .delay(5000)
                    //     zip(observableRange(1, 120), (_, i) => i),
                    //     mergeMap(i => {
                    //       return observableTimer(i * 1000);
                    //     }),)
                    // ),catchError(this.handleErrorReload),);
  }

  listDevices(data: any): Observable<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    // return this.http.post(this.BASE_URL + 'device/list', {}, {headers: headers})
    //                 // .pipe(map((res: Response) => res.json()))
    //                 .toPromise()
    //                 .catch(this.handleError);
    return this.http.post(this.BASE_URL + 'device/list', data, {headers: headers})
                    .pipe(catchError(this.handleError));
  }

  listBoards(data: any): Observable<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    // return this.http.post(this.BASE_URL + 'device/list', {}, {headers: headers})
    //                 // .pipe(map((res: Response) => res.json()))
    //                 .toPromise()
    //                 .catch(this.handleError);
    return this.http.post(this.BASE_URL + 'board/list', data, {headers: headers})
                    .pipe(catchError(this.handleError));
  }

  testBoards(data: any): Observable<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    // return this.http.post(this.BASE_URL + 'device/list', {}, {headers: headers})
    //                 // .pipe(map((res: Response) => res.json()))
    //                 .toPromise()
    //                 .catch(this.handleError);
    return this.http.post(this.BASE_URL + 'board/test', data, {headers: headers})
                    .pipe(catchError(this.handleError));
  }

  getModels(): Promise<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'model/list', {} , {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }

  private handleError (error) {
    console.log(error);
    console.log(error.error);
    console.log(error.message);
    // window.location.reload();
    return observableThrowError(error);
  }
  private handleErrorReload (error) {
    console.log(error);
    window.location.reload();
    return observableThrowError(error);
  }

}
