
import {throwError as observableThrowError, timer as observableTimer, range as observableRange,  Observable } from 'rxjs';

import {mergeMap, zip, catchError, retryWhen,  map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {debounceTime} from 'rxjs/operators';

@Injectable()
export class ArchiveService {
  private BASE_URL = '/api/';
  private headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
  constructor(private http: HttpClient) {}

  getArchive(data: any): Observable<any> {
    var headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'archive/get', data, {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    .pipe(retryWhen(
                      attempts =>
                      attempts.pipe(
                        zip(observableRange(1, 120), (_, i) => i),
                        mergeMap(i => {
                          return observableTimer(i * 1000);
                        }),)
                    ),
                    catchError(this.handleError),);
  }

  getMonthlyData(data: any): Observable<any> {
    var headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'archive/getMonthlyData', data, {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    .pipe(catchError(this.handleError));
  }

  private handleError (error) {
    console.log(error);
    // window.location.reload();
    return observableThrowError(error);
  }

}
