import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthService) {}

    canActivate() {
        if (localStorage.getItem('isLoggedin')) {
            this.auth.ensureAuthenticated('')
            .then((res) => {
                // console.log(res.success);
                if (res.success) {
                    // console.log('logged in');
                    // this.router.navigateByUrl(this.router.url);
                    // return true;
                    if (this.router.url.indexOf('operations') === -1 && this.router.url.indexOf('properties') === -1) {
                        const isOperator = localStorage.getItem('isOperator');
                        const isRoute = localStorage.getItem('isRoute');
                        console.log('isOperator(2) ', isOperator);

                        if (isOperator && isOperator === 'true') {
                            if (isRoute && isRoute === 'true') {
                                this.router.navigate(['/route']);
                            } else {
                                this.router.navigate(['/operations/devices']);
                            }
                        } else {
                            this.router.navigate(['/device']);
                        }
                    }

                } else {
                    localStorage.removeItem('token');
                    localStorage.removeItem('isLoggedin');
                    localStorage.removeItem('isOperator');
                    localStorage.removeItem('isRoute');
                    localStorage.removeItem('isService');
                    this.router.navigate(['/login']);
                    // return false;
                }
            })
            .catch((err) => {
                console.log(err);
                localStorage.removeItem('token');
                localStorage.removeItem('isLoggedin');
                localStorage.removeItem('isOperator');
                localStorage.removeItem('isRoute');
                localStorage.removeItem('isService');
                this.router.navigate(['/login']);
                // return false;
            });

            // console.log('logged in');
            return true;
        } else if (localStorage.getItem('isService')) {
            this.auth.ensureAuthenticated(localStorage.getItem('isService'))
            .then((res) => {
                // console.log(res.success);
                if (res.success) {
                    // console.log('logged in');
                } else {
                    localStorage.removeItem('token');
                    localStorage.removeItem('isService');
                    localStorage.removeItem('isOperator');
                    localStorage.removeItem('isLoggedin');
                    localStorage.removeItem('isRoute');
                    this.router.navigate(['/service/login']);
                }
            })
            .catch((err) => {
                console.log(err);
                localStorage.removeItem('token');
                localStorage.removeItem('isService');
                localStorage.removeItem('isOperator');
                localStorage.removeItem('isLoggedin');
                localStorage.removeItem('isRoute');
                this.router.navigate(['/service/login']);
            });
            return true;
        
        } else {
            
            localStorage.removeItem('token');
            localStorage.removeItem('isLoggedin');
            localStorage.removeItem('isOperator');
            localStorage.removeItem('isService');
            localStorage.removeItem('isRoute');
            this.router.navigate(['/login']);
            return false;
        }
    }
}
