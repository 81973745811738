import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    // Log the error to the console
    console.error('An error occurred:', error);

    // Optionally, send the error to a server or logging service
    // this.loggingService.logError(error);
  }
}