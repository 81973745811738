
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class TicketService {
  private BASE_URL = '/api/';
  private headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
  constructor(private http: HttpClient) {}


  listTicket(input): Promise<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'ticket/list', input, {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }

  updateTicket(input): Promise<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'ticket/update', input, {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }

  // getTicket(input): Promise<any> {
  //   // var headers = this.headers.set('Authorization', localStorage.getItem('token'));
  //   return this.http.post(this.BASE_URL + 'ticket/get', input)
  //                   // .pipe(map((res: Response) => res.json()))
  //                   .toPromise()
  //                   .catch(this.handleError);
  // }
  getTicket(input): Promise<any> {
    return this.http.post(this.BASE_URL + 'ticket/get', input, {headers: this.headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }

  getSummary(input): Promise<any> {
    return this.http.post(this.BASE_URL + 'summary/get', input, {headers: this.headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }

  private handleError (error) {
    console.log(error);
    // window.location.reload();
    return observableThrowError(error);
  }
}
