import { Component } from '@angular/core';
import { AuthService } from './shared/services';
import { DBGlobal } from './shared/services';
import { Router, NavigationEnd, Event, NavigationStart } from '@angular/router';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public loading = false;
    constructor(public router: Router, private _auth: AuthService, public _DBGlobal: DBGlobal) {
        this.router.events.subscribe( (event: Event) => {
            if (event instanceof NavigationStart) {
                /*Display your spinner*/
                this.loading = true;
                // console.log('nav start ' +  (new Date()).toString())
            }
            if (event instanceof NavigationEnd) {
                /*Hide your spinner*/
                this.loading = false;
                // console.log('nav stop ' +  (new Date()).toString())
            }
        });
        this._auth
            .global()
            .then((res) => {
                if (res.success && res.data) {
                    this._DBGlobal.global = JSON.parse(res.data);
                    console.log(this._DBGlobal.global);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

}
