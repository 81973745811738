import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, SignGuard } from './shared/guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'core',
        loadChildren: () => import('./core/core.module').then(m => m.CoreModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('./reset/reset.module').then(m => m.ResetModule),
        canActivate: [SignGuard]
    },
    // {
    //     path: 'signup',
    //     loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule),
    //     canActivate: [SignGuard]
    // },
    {
        path: 'start',
        loadChildren: () => import('./start/start.module').then(m => m.StartModule),
        canActivate: [SignGuard]
    },
    // {
    //     path: 'reset',
    //     loadChildren: () => import('./reset/reset.module').then(m => m.ResetModule),
    //     canActivate: [SignGuard]
    // },
    {
        path: 'media/:type/:media',
        loadChildren: () => import('./media/media.module').then(m => m.MediaModule)
    },
    {
        path: 'ticket/:id',
        loadChildren: () => import('./ticket/ticket.module').then(m => m.TicketModule)
    },
    {
        path: 'email/:id',
        loadChildren: () => import('./email/email.module').then(m => m.EmailModule)
    },
    {
        path: 'summary/:id',
        loadChildren: () => import('./summary/summary.module').then(m => m.SummaryModule)
    },
    {
        path: 'feedback/:id',
        loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule)
    },
    // {
    //     path: 'service',
    //     loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    //     canActivate: [ServiceAuthGuard]
    // },
    {
        path: 'service', // path: 'service/login',
        loadChildren: () => import('./service/service.module').then(m => m.ServiceModule),
        canActivate: [SignGuard]
    },
    { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },
    { path: '**', redirectTo: 'not-found' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
