import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class PropertyService {
  private BASE_URL = '/api/';
  private headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) {}

  list(data: any): Observable<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'property/list', data, { headers: headers })
                    .pipe(catchError(this.handleError));
  }

  getNextService(dateString, frequency, serviceDays = [5]) {
    serviceDays = serviceDays.filter(value => value !== 0);
    const [year, month, day] = dateString.split('-').map(Number);
    const givenDate = new Date(year, month - 1, day); // month is 0-indexed
    const currentDate = new Date();
  
    // Calculate the next service date
    const weeks = Math.floor(frequency);
    const extraDays = Math.round((frequency - weeks) * 7);
    let nextServiceDate = new Date(givenDate);
    nextServiceDate.setDate(nextServiceDate.getDate() + (weeks * 7) + extraDays);
  
    // Find the next available service day
    const dayOfWeek = nextServiceDate.getDay();
    let daysToAdd = Infinity;
    for (const serviceDay of serviceDays) {
      const diff = (serviceDay - dayOfWeek + 7) % 7;
      if (diff < daysToAdd) {
        daysToAdd = diff;
      }
    }
    nextServiceDate.setDate(nextServiceDate.getDate() + daysToAdd);
  
    // Calculate the difference in time
    const timeDifference = nextServiceDate.getTime() - currentDate.getTime();
    const totalDays = Math.max(0, Math.ceil(timeDifference / (1000 * 3600 * 24))); // Ensure minimum of 0 days
  
    const resultWeeks = Math.floor(totalDays / 7);
    const resultDays = totalDays % 7;
  
    return `${resultWeeks}.${resultDays}`;
  }

  private handleError(error) {
    console.log(error);
    console.log(error.error);
    console.log(error.message);
    return throwError(() => new Error(error.message));
  }
}