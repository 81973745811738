import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class PropertyService {
  private BASE_URL = '/api/';
  private headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) {}

  list(data: any): Observable<any> {
    const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'property/list', data, { headers: headers })
                    .pipe(catchError(this.handleError));
  }

  private handleError(error) {
    console.log(error);
    console.log(error.error);
    console.log(error.message);
    return throwError(() => new Error(error.message));
  }
}