import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';

@Injectable()
export class SignGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate() {
        if (localStorage.getItem('isLoggedin')) {
            const isOperator = localStorage.getItem('isOperator');
            console.log('isOperator(1) ', isOperator);
            if (isOperator && isOperator === 'true') {
                this.router.navigate(['/operations/devices']);
            } else {
                this.router.navigate(['/device']);
            }
            // this.router.navigate(['/device']);
            return false;
        }
        if (localStorage.getItem('isService')) {
            this.router.navigate(['/device']);
            return false;
        }
        return true;
    }
}
