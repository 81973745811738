
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class MapQuestService {
  private BASE_URL = '/api/';
  private headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
  constructor(private http: HttpClient) {}


  orderStops(input): Promise<any> {
    // const headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(
                    'https://www.mapquestapi.com/directions/v2/optimizedroute?key=ZlXFsKP5dWP67NX0Gnv3FCoi08DPdDO1',
                     input)
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }


  private handleError (error) {
    console.log(error);
    // window.location.reload();
    return observableThrowError(error);
  }
}
